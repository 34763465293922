import { projectType,  subProjectTypeArch, subProjectTypeUrb} from "./constants"
import React from "react"

import Arhitectura1 from "../images/Arhimar2.jpg"
import Arhitectura2 from "../images/Arhimar2.jpg"

// Arhitectura semicolective
import Olar1_mainImg from "../images/proiecte/arhitectura/1.1. LOCUINTE SEMICOLECTIVE/OLAR 1/1.1.C. OLAR RANDARE 1.jpg"
import Forna_mainImg from "../images/proiecte/arhitectura/1.1. LOCUINTE SEMICOLECTIVE/FORNA/1.1.E.1 FORNA RANDARE 1.jpg"

// Arhitectura individuale
import Caian_mainImg from "../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.A. CAIAN/1.2.A. CAIAN RANDARE 1.jpg"
import Mercea_mainImg from "../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.B. MERCEA/1.2.B.1 CASA MERCEA RANDARE 1.jpg"
import Zmeurisului_mainImg from "../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.C. ZMEURISULUI/1.2.C.1 Randare 1.jpg"

// Arhitectura semicolective
const Olar1_path = require.context('../images/proiecte/arhitectura/1.1. LOCUINTE SEMICOLECTIVE/OLAR 1', true);
const Forna_path = require.context('../images/proiecte/arhitectura/1.1. LOCUINTE SEMICOLECTIVE/FORNA', true);

// Arhitectura individuale
const Caian_path = require.context('../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.A. CAIAN', true);
const Mercea_path = require.context('../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.B. MERCEA', true);
const Zmeurisului_path = require.context('../images/proiecte/arhitectura/1.2. LOCUINTE INDIVIDUALE/1.2.C. ZMEURISULUI', true);

const desc1 = <div>Lorem Ipsum is simply <br></br>nting and typese</div>

function getImagesArray(images) {
    const imageKeys = images.keys();
    const imageArray = imageKeys.map((imageKey) => images(imageKey));
    return imageArray;
}

export const projectData =
    [
        {
            type: projectType.arhitectura,
            active: false,
            subTypes:
                [
                    {
                        type: subProjectTypeArch.semicolective,
                        active: false,
                        projects:
                            [
                                {
                                    title: "OLAR 1",
                                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, \n when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                                    images: getImagesArray(Olar1_path),
                                    projectImage: Olar1_mainImg,
                                },
                                {
                                    title: "Forna",
                                    description: desc1,
                                    images: getImagesArray(Forna_path),
                                    projectImage: Forna_mainImg,
                                }
                            ]
                    },
                    {
                        type: subProjectTypeArch.individuale,
                        active: false,
                        projects:
                            [
                                {
                                    title: "Caian",
                                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, \n when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                                    images: getImagesArray(Caian_path),
                                    projectImage: Caian_mainImg,
                                },
                                {
                                    title: "MERCEA",
                                    description: desc1,
                                    images: getImagesArray(Mercea_path),
                                    projectImage: Mercea_mainImg,
                                },
                                {
                                    title: "ZMEURISULUI",
                                    description: desc1,
                                    images: getImagesArray(Zmeurisului_path),
                                    projectImage: Zmeurisului_mainImg,
                                }
                            ]
                    }
                ]

        },
        {
            type: projectType.urbanism,
            active: false,
            subTypes:
                [
                    {   type: subProjectTypeUrb.urb,
                        projects:
                        [
                            {
                                title: "Proiect urbanism 1",
                                description: "descriere1",
                                images: [Arhitectura1],
                                projectImage: Arhitectura1,
                            },
                            {
                                title: "Proiect urbanism 2",
                                description: "descriere2",
                                images: [Arhitectura1],
                                projectImage: Arhitectura1,
                            },
                            {
                                title: "Proiect urbanism 3",
                                description: "descriere3",
                                images: [Arhitectura1],
                                projectImage: Arhitectura1,
                            },
                            {
                                title: "Proiect urbanism 4",
                                description: "descriere3",
                                images: [Arhitectura1],
                                projectImage: Arhitectura1,
                            }
                        ]}
                ]
            
        },
        {
            type: projectType.design,
            active: false,
            projects:
                [
                    {
                        title: "Proiect design 1",
                        description: "descriere1",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 2",
                        description: "descriere2",
                        images: [Arhitectura2],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 3",
                        description: "descriere3",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 4",
                        description: "descriere2",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 5",
                        description: "descriere3",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 6",
                        description: "descriere3",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 7",
                        description: "descriere2",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    },
                    {
                        title: "Proiect design 8",
                        description: "descriere3",
                        images: [Arhitectura1],
                        projectImage: Arhitectura1,
                    }
                ]
        }
    ]

import React from "react"
import { MDBContainer } from "mdbreact"
import CarouselPage from "./CarouselPage"
import { projectData } from "../resources/projectData"

import queryString from "query-string"

const ProjectDetails = ({ search }) => {
    const { titlu, tip } = queryString.parse(search);
    let tipList = [];
    if (tip) {
        try {
            tipList = JSON.parse(decodeURIComponent(tip));
        } catch (error) {
            console.error('Eroare la parsarea tipului:', error);
        }
    }

    let data = {};
    let project = {};
    if (projectData) {
        let searchList = projectData;
        tipList.map((tip) => {
            data = searchList.find(data => data.type === tip)
            if (data) {
                if (data.subTypes) {
                    searchList = data.subTypes;
                }
                else {
                    project = data.projects.find((project) => project.title === titlu);
                    return {};
                }
            }
            return {};
        });
    }


    return (
        <MDBContainer className="mt-4 container pt-5 my-5">
            <MDBContainer className="p-md-3 ">
                <h2 class="text-uppercase text-center mx-auto font-weight-bold mb-4 pb-2">{project.title}</h2>
            </MDBContainer>
            <MDBContainer>
                <div class="row justify-content-center">
                    <div class="col-md-8 mb-4">
                        <CarouselPage project={project} />
                    </div>
                    <div class="col-md-4 mb-4">
                        {project.description}
                    </div>
                </div>
            </MDBContainer>
        </MDBContainer>
    )
}

export default ProjectDetails;
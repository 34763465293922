import React from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact"

import {
  TransformWrapper,
  TransformComponent,
  useControls
} from "react-zoom-pan-pinch";

const CarouselPage = ({ project }) => {
  const [isImageExpanded, setIsImageExpanded] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const handleImageClick = (index) => {
    setIsImageExpanded(true);
    setCurrentImageIndex(index);
  };

  const handleCloseClick = () => {
    setIsImageExpanded(false);
  };

  const Controls = () => {
    const { zoomIn, zoomOut } = useControls();
    return (
      <>
        <i class="fa fa-search-plus fa-2x zoom-buttons"onClick={() => zoomIn()}/>
        <i class="fa fa-search-minus fa-2x zoom-buttons" onClick={() => zoomOut()}/>
      </>
    );
  };

  let images = []
  if (project.images) {
    images = project.images
  }

  return (
    <MDBContainer>
      <MDBCarousel
        activeItem={1}
        length={images.length}
        showControls
        slide={true}
        showIndicators
        className="z-depth-1"
      >
        <MDBCarouselInner>
          {images.map((image, index) => (
            <MDBCarouselItem itemId={index + 1} key={index}>
              <MDBView>
                <a href="#"  onClick={() => handleImageClick(index)}>
                  <img
                    className="d-block w-100 h-100 img-fluid"
                    src={image}
                    alt={`${index + 1}st slide`}
                  />
                </a>
              </MDBView>
            </MDBCarouselItem>
          ))}
        </MDBCarouselInner>
      </MDBCarousel>
      <MDBModal isOpen={isImageExpanded} toggle={handleCloseClick} centered size='xl'>
        <TransformWrapper>
          <MDBModalHeader toggle={handleCloseClick}>
            <b className='text-uppercase'>{project.title}</b>
          </MDBModalHeader>
          <MDBModalBody>
            <TransformComponent>
              <img
                className="d-block w-100 h-100 img-fluid"
                src={images[currentImageIndex]}
                alt={`${currentImageIndex + 1}st slide`}
              />
            </TransformComponent>

          </MDBModalBody>
          <MDBModalFooter center>
          <Controls />
          </MDBModalFooter>
        </TransformWrapper>
      </MDBModal>
    </MDBContainer>
  )
}

export default CarouselPage;

export const projectType = {
    arhitectura: "Arhitectura",
    urbanism: "Urbanism",
    design: "Design"
}

export const subProjectTypeArch = {
    semicolective: "Locuinte Semicolective",
    individuale: "Locuinte Individuale"
}

export const subProjectTypeUrb = {
    urb: "Urb"
}


export const pages = {
    despreNoi: "despreNoi",
    servicii: "servicii",
    proiecte: "proiecte",
    echipa: "echipa",
    contact: "contact"
}

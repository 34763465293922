import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ProjectDetails from "../components/ProjectDetails"

const detaliiProiect = ({location:{search}}) => (
  <div>
    <Helmet>
      <title>Endorfina</title>
      <meta
        name="Solidreams, we print your dreams, 3d printing, Romania, printare 3D, imprimanta 3D"
        content="Romania, 3D printing, printare 3D, 3D, 3d, obiecte 3D"
      />
    </Helmet>
    <Navbar />
    <ProjectDetails search={search} />
    <Footer />
  </div>
  
)


export default detaliiProiect